import React from 'react';
import Steps from './Steps';
import VWLink from './VWLink';

const Home = () => (
  <>
    <Steps />
    <VWLink />
  </>
);

export default Home;
