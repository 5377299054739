import React from 'react';

const ContactInformation = () => (
  <>
    <div className="text-center step-text">
      <p>Questions or Feedback? Let us know!</p>
      <p>nick@schustersoftware.ca</p>
    </div>
  </>
);

export default ContactInformation;
