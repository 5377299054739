import React from 'react';

const Footer = () => (
  <>
    <div id="footer-padding" />
    <p className="text-center mt-5">
      &#169; 2021
      {' '}
      <a href="https://www.schustersoftware.ca">Schuster Software</a>
    </p>
  </>
);

export default Footer;
