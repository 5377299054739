import React from 'react';
import {
  Container, Row, Col, Tab, Nav,
} from 'react-bootstrap';
import ContactInformation from './ContactInformation';
import Home from './Home';

// Header.
const Header = () => (
  <>
    <p className="header-text">Virtual Whiteboard</p>
    <div>
      <Tab.Container defaultActiveKey="home">
        <Container>
          <Row>
            <Col className="text-right">
              <Nav.Link eventKey="home">Home</Nav.Link>
            </Col>
            <Col className="text-left">
              <p><Nav.Link eventKey="contact">Contact</Nav.Link></p>
            </Col>
          </Row>
        </Container>
        <Tab.Content className="m-5">
          <Tab.Pane eventKey="home">
            <Home />
          </Tab.Pane>
          <Tab.Pane eventKey="contact">
            <ContactInformation />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  </>
);

export default Header;
