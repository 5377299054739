import * as React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';

// CSS
import '../css/global.scss';

// Gatsby root page.
const Index = () => (
  <>
    <Helmet>
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      <title> Virtual Whiteboard Home </title>
      <meta
        name="description"
        content="Virtual Whiteboard is a real time whiteboard solution that is pay-as-you-go."
      />
    </Helmet>
    <App />
  </>
);

export default Index;
