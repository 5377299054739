import React from 'react';
import {
  Row, Col, Container, Image,
} from 'react-bootstrap';

// Images
import StepOne from '../images/StepOne.png';
import StepTwo from '../images/StepTwo.png';
import StepThree from '../images/StepThree.png';

const Steps = () => (
  <>
    <Container fluid>
      <Row>
        <Col md="12" lg="6">
          <Row>
            <Col xs="4">
              <Image
                width={256}
                height={256}
                fluid
                className="mr-3 float-lg-right"
                src={StepOne}
                alt="Generic placeholder"
              />
            </Col>
            <Col className="my-auto">
              <p className="step-text my-auto">Get a creator code based on your needs.</p>
            </Col>
          </Row>
        </Col>
        <Col md="12" lg="6">
          <Row>
            <Col xs="4">
              <Image
                width={256}
                height={256}
                fluid
                className="mr-3 float-lg-right"
                src={StepTwo}
                alt="Generic placeholder"
              />
            </Col>
            <Col className="my-auto">
              <p className="step-text my-auto">Create a room using the Virtual Whiteboard app.</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg="6">
          <Row>
            <Col xs="4">
              <Image
                width={256}
                height={256}
                fluid
                className="mr-3 float-lg-right"
                src={StepThree}
                alt="Generic placeholder"
              />
            </Col>
            <Col className="my-auto">
              <p className="step-text my-auto">Invite users using the room code.</p>
            </Col>
          </Row>
        </Col>

      </Row>
    </Container>
  </>
);

export default Steps;
