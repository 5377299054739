import React from 'react';
import { Image } from 'react-bootstrap';

// Image.
import AppIcon from '../images/logo512.png';

const VWLink = () => (
  <>
    <p className="link-text">Draw!</p>
    <div className="justify-content-center d-flex m-3 link-image-container">
      <Image
        onClick={() => window.open('http://app.virtual-whiteboard.ca')}
        width={512}
        height={512}
        fluid
        className="mx-auto link-image shadow"
        src={AppIcon}
        alt="App logo."
      />
    </div>
    <div className="text-center">
      <p>
        App is currenlty in beta.
        <br />
        Use creator code &quot;demo&quot; for 30 minutes of access!
      </p>
    </div>

  </>
);

export default VWLink;
