import React from 'react';
import Header from './Header';
import Footer from './Footer';

// Root page content.
const App = () => (
  <>
    <Header />
    <Footer />
  </>
);

export default App;
